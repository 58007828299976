import { Chip } from "@mui/material";
import { FC } from "react";
import { getAdvertisementStatus } from "./advertisement-status.constants";

interface AdvertisementStatusProps {
  advertisement: Advertisement;
}

const AdvertisementStatus: FC<AdvertisementStatusProps> = ({ advertisement }) => {
  const status = getAdvertisementStatus(advertisement);
  return (
    <Chip
      sx={{ width: "fit-content" }}
      size="small"
      label={status.label.toUpperCase()}
      color={status.color}
    />
  );
};

export default AdvertisementStatus;
