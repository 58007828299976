import StoreIcon from "@mui/icons-material/Store";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import AssistantIcon from "@mui/icons-material/Assistant";
import RecommendIcon from "@mui/icons-material/Recommend";
import UserIcon from "@mui/icons-material/AccountCircle";
import RewardsIcon from "@mui/icons-material/EmojiEvents";
import RateReviewIcon from "@mui/icons-material/RateReview";
import GradeIcon from "@mui/icons-material/Grade";
import { NotificationAdd } from "@mui/icons-material";

interface DrawerRoutes {
  [key: string]: DrawerRoute;
}

export const drawerRoutes: DrawerRoutes = {
  "home": {
    path: "/",
    name: "Dashboard",
    icon: (color: any) => <DashboardIcon color={color} />,
  },
  "negocios": {
    path: "/negocios",
    name: "Negocios",
    icon: (color: any) => <StoreIcon color={color} />,
    nested: {
      crear: "Crear",
      editar: "Editar",
      detalle: "Detalle",
    },
  },
  "admin-negocios": {
    path: "/admin-negocios",
    name: "Admin negocios",
    icon: (color: any) => <UserIcon color={color} />,
    nested: {
      crear: "Crear",
      editar: "Editar",
    },
  },
  "cupones": {
    path: "/cupones",
    name: "Cupones",
    icon: (color: any) => <ConfirmationNumberIcon color={color} />,
    nested: {
      crear: "Crear",
      editar: "Editar",
      detalle: "Detalle",
    },
  },
  "notificaciones": {
    path: "/notificaciones",
    name: "Notificaciones",
    icon: (color: any) => <NotificationAdd color={color} />,
  },
  "sugerencias": {
    path: "/sugerencias",
    name: "Sugerencias",
    icon: (color: any) => <AssistantIcon color={color} />,
    nested: {},
  },
  "recomendaciones": {
    path: "/recomendaciones",
    name: "Recomendaciones",
    icon: (color: any) => <RecommendIcon color={color} />,
    nested: {
      ver: "Detalle",
    },
  },
  "recompensas": {
    path: "/recompensas",
    name: "Recompensas",
    icon: (color: any) => <RewardsIcon color={color} />,
    nested: {},
  },
  "opiniones": {
    path: "/opiniones",
    name: "Opiniones",
    icon: (color: any) => <RateReviewIcon color={color} />,
    nested: {},
  },
  "publicidades": {
    path: "/publicidades",
    name: "Publicidades",
    icon: (color: any) => <GradeIcon color={color} />,
    nested: {
      crear: "Crear",
      editar: "Editar",
      detalle: "Detalle",
    },
  },
};
