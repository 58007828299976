import { del, get, post, put } from "./api.service";

const endpoint = "/advertisements";

export interface AdvertisementsBody {
  name: string;
  startDate: string;
  endDate: string;
  storeId: string;
}

interface QueryCoupons {
  name?: string;
  sortBy?: string;
}

const queryAdvertisements = (query?: QueryCoupons) =>
  get<Paginated<Advertisement>>({
    endpoint,
    auth: true,
    axiosConfig: {
      params: {
        // page: 1,
        // limit: 9999,
        // name: query?.name,
        // sortBy: query?.sortBy,
      },
    },
  });

const getAdvertisementDetails = (advertisementId: string) =>
  get<Advertisement>({
    endpoint: endpoint + "/" + advertisementId,
    auth: true,
  });

const createAdvertisement = (body: AdvertisementsBody) =>
  post({
    endpoint,
    auth: true,
    body,
  });

const deleteAdvertisement = (advertisementId: string) =>
  del({
    endpoint: endpoint + "/" + advertisementId,
    auth: true,
    body: undefined,
  });

const updateAdvertisement = (advertisementId: string, body: AdvertisementsBody) =>
  put({
    endpoint: endpoint + "/" + advertisementId,
    auth: true,
    body,
  });

export const AdvertisementsApiService = {
  queryAdvertisements,
  createAdvertisement,
  deleteAdvertisement,
  getAdvertisementDetails,
  updateAdvertisement,
};
