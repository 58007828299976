const constants = {
  title: {
    edit: (coupon?: string) => `Editar ${coupon ?? "publicidad"}`,
    create: "Crear publicidad",
    default: "Publicidad",
  },
  errorFetchingCoupon: "Error al buscar publicidad",
  error: (isEdit: boolean) => `Error al ${isEdit ? "editar" : "crear"} publicidad`,
  success: (isEdit: boolean) => `Publicidad ${isEdit ? "editado" : "creado"} correctamente !`,
  buttons: {
    delete: "Eliminar",
    goBack: "Volver",
    save: (isEdit: boolean) => (isEdit ? "Guardar" : "Crear"),
    next: "Siguiente",
  },
  labels: {
    name: "Nombre *",
    province: "Provincia *",
    city: "Ciudad *",
    store: "Negocio *",
    startDate: "Desde *",
    endDate: "Vencimiento *",
  },
  rules: {
    required: "Este campo es requerido",
  },
};

export default constants;

type AdvertisementFormInput = {
  name: string;
  storeId: Nullable<string>;
  startDate: Nullable<string>;
  endDate: Nullable<string>;
};

export const formDefaultValues: AdvertisementFormInput = {
  name: "",
  storeId: "",
  startDate: null,
  endDate: null,
};
