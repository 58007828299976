import { createContext, useCallback, useEffect, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import { CategoryApiService } from "../../api/category.api.service";
import { GroupedCities, LocationApiService } from "../../api/location.api.service";
import {
  SocialMediaInput,
  socialMedias,
} from "../../screens/stores-form/components/social-media-select/social-media-select.constants";

export type StoreFormInput = {
  name: string;
  description: string;
  categoryId: Nullable<string>;
  provinceId: Nullable<string>;
  cityId: Nullable<string>;
  logo: Nullable<File>;
  cover: Nullable<File>;
  features: string[];
  images: any[];
  phoneNumber: string;
  email: string;
  webpage: string;
  socialMedia: SocialMediaInput;
  hours: string;
  address: string;
  coordinates: {
    lat: number;
    lon: number;
  };
  active: boolean;
  isPartner: boolean;
};

export const formDefaultValues: StoreFormInput = {
  name: "",
  description: "",
  address: "",
  categoryId: "",
  provinceId: "",
  cityId: "",
  logo: null,
  cover: null,
  features: [],
  email: "",
  hours: "",
  images: [],
  phoneNumber: "",
  socialMedia: socialMedias,
  webpage: "",
  coordinates: {
    lat: 0,
    lon: 0,
  },
  active: true,
  isPartner: false,
};

export interface LocationItem {
  id: string;
  name: string;
}

interface Actions {
  setValuesFromStore: (store: Store, setValue: any) => void;
  setValuesFromRecommendation: (recommendation: Recommendation, setValue: any) => void;
  setCities: (cities: City[]) => void;
}

const actions: Actions = {
  setValuesFromStore: (_: Store, _2: any) => {},
  setValuesFromRecommendation: (_: Recommendation, _2: any) => {},
  setCities: (_: City[]) => {},
};

interface StoresFormState {
  categories: Category[];
  groupedCities: GroupedCities[];
  provinces: LocationItem[];
  cities: LocationItem[];
}

const defaultState: StoresFormState = {
  categories: [],
  groupedCities: [],
  provinces: [],
  cities: [],
};

export const StoresFormContext = createContext({ ...defaultState, ...actions });

const StoresFormContextProvider = ({ children }: { children: JSX.Element }) => {
  const [categories, setCategories] = useState<Category[]>(defaultState.categories);
  const [groupedCities, setGroupedCities] = useState<GroupedCities[]>(defaultState.groupedCities);
  const [provinces, setProvinces] = useState<LocationItem[]>(defaultState.provinces);
  const [cities, setCities] = useState<LocationItem[]>(defaultState.cities);

  useEffect(() => {
    LocationApiService.getCitiesGroup().then((groupRes) => {
      setGroupedCities(groupRes.data);
    });
    CategoryApiService.getCategories().then((categoryRes) => {
      setCategories(categoryRes.data.results);
    });
  }, []);

  useEffect(() => {
    setProvinces(
      groupedCities.map((group) => ({
        id: group.province.id,
        name: group.province.name,
      }))
    );
  }, [groupedCities]);

  const setValuesFromStore = useCallback(
    (store: Store, setValue: UseFormSetValue<StoreFormInput>) => {
      for (const key of Object.keys(formDefaultValues)) {
        switch (key) {
          case "socialMedia": {
            const socials = { ...socialMedias };

            store.socialMedia.forEach((social) => {
              socials[social.name] = {
                ...socials[social.name],
                checked: true,
                url: social.url ?? "",
              };
            });
            setValue(key, socials);
            break;
          }
          case "provinceId": {
            setValue(key, store.city.provinceId);
            break;
          }
          case "cityId": {
            // handled by useEffect
            setValue(key, "");
            break;
          }
          case "categoryId": {
            setValue(key, store.category._id);
            break;
          }
          case "logo": {
            setValue(key, null);
            break;
          }
          case "cover": {
            setValue(key, null);
            break;
          }
          case "isPartner": {
            setValue(key, store.tier === "partner");
            break;
          }
          default: {
            const castedKey = key as keyof Store;
            // @ts-ignore
            setValue(key, store[castedKey] ?? "");
          }
        }
      }
    },
    [socialMedias]
  );

  const setValuesFromRecommendation = useCallback(
    (recommendation: Recommendation, setValue: UseFormSetValue<StoreFormInput>) => {
      setValue("provinceId", recommendation.city.provinceId);
      setValue("cityId", recommendation.city.cityId);
      setValue("name", recommendation.name);
      setValue("address", recommendation.address);
      setValue("features", recommendation.features);
      setValue("phoneNumber", recommendation.phone);
      setValue("categoryId", recommendation.category.id);
    },
    []
  );

  return (
    <StoresFormContext.Provider
      value={{
        categories,
        groupedCities,
        provinces,
        cities,
        setValuesFromStore,
        setValuesFromRecommendation,
        setCities,
      }}
    >
      {children}
    </StoresFormContext.Provider>
  );
};

export default StoresFormContextProvider;
