import { Grid, MenuItem } from "@mui/material";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import {
  ControlledFormInput,
  ControlledFormSelect,
  ControlledFormSwitch,
} from "../../../../common/components/ui";
import useStoresForm from "../../../../common/hooks/useStoresForm";
import { StoresFormStepProps } from "../../stores-form-screen.component";
import { ControlledFeaturesSelect } from "../features-select/features-select.component";
import storesFormStepsConstants from "./stores-form-steps.constants";

const StoresFormStepOne: FC<StoresFormStepProps> = () => {
  const { categories } = useStoresForm();
  const { control } = useFormContext();

  return (
    <Grid container mt={3}>
      <Grid item md={6} xs={12}>
        <Grid item xs={12} sm={10} mb={2}>
          <ControlledFormInput
            name="name"
            control={control}
            label={storesFormStepsConstants.labels.name}
            rules={{
              required: "Este campo es requerido",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={10} mb={2}>
          <ControlledFormInput
            name="description"
            control={control}
            multiline
            label={storesFormStepsConstants.labels.description}
            rules={{
              required: "Este campo es requerido",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={10} mb={2}>
          <ControlledFormSelect
            name="categoryId"
            control={control}
            rules={{
              required: "Este campo es requerido",
            }}
            label={storesFormStepsConstants.labels.category}
          >
            {categories.map((category) => (
              <MenuItem key={"category-" + category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </ControlledFormSelect>
        </Grid>
        <Grid item container xs={12} sm={10} mb={2}>
          <Grid item xs={6}>
            <ControlledFormSwitch
              name="active"
              control={control}
              label={storesFormStepsConstants.labels.active}
            />
          </Grid>
          <Grid item xs={6}>
            <ControlledFormSwitch
              name="isPartner"
              control={control}
              label={storesFormStepsConstants.labels.partner}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} xs={12}>
        <Grid item xs={12} sm={10} mb={2}>
          <ControlledFeaturesSelect
            name="features"
            control={control}
            label={storesFormStepsConstants.labels.features}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StoresFormStepOne;
