import { DateTime } from "luxon";

const isExpired = (advertisement: Advertisement) => {
  return DateTime.fromISO(advertisement.endDate) < DateTime.now();
};

const hasStarted = (advertisement: Advertisement) => {
  return DateTime.fromISO(advertisement.startDate) < DateTime.now();
};

const isActive = (advertisement: Advertisement) => {
  return !isExpired(advertisement) && hasStarted(advertisement);
};

const advertisementStatus = {
  expired: "expired",
  active: "active",
  inactive: "inactive",
};

export const advertisementUtils = {
  isExpired,
  hasStarted,
  isActive,
  advertisementStatus,
};
