import { Grid, MenuItem, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import useLayout from "../../common/hooks/useLayout";
import { withLayout } from "../../hocs/with-layout";
import { ButtonsContainer } from "./advertisements-form-screen.styles";
import {
  Button,
  ControlledFormInput,
  ControlledFormSelect,
  FormSelect,
} from "../../common/components/ui";
import advertisementsFormScreenConstants, {
  formDefaultValues,
} from "./advertisements-form-screen.constants";
import { FormProvider, useForm } from "react-hook-form";
import useSnackbar from "../../common/hooks/useSnackbar";
import NotFoundAnimation from "../not-found/components/not-found-animation.component";
import { StoreApiService } from "../../api/store.api.service";
import { DateTime } from "luxon";
import { AdvertisementsApiService, AdvertisementsBody } from "../../api/advertisements.api.service";
import { GroupedCities, LocationApiService } from "../../api/location.api.service";
import { LocationItem } from "../../common/context/stores-form.context";

const AdvertisementsFormScreen = () => {
  const { advertisementId } = useParams();
  const { setTitle } = useLayout();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const formMethods = useForm({
    defaultValues: formDefaultValues,
  });

  const [advertisementToEdit, setAdvertisementToEdit] = useState<Advertisement>();
  const [notFound, setNotFound] = useState<boolean>(false);
  const [stores, setStores] = useState<SummaryStore[]>([]);
  const [fetchingStores, setFetchingStores] = useState<boolean>(true);
  const [cityId, setCityId] = useState<string>();
  const [provinceId, setProvinceId] = useState<string>();
  const [groupedCities, setGroupedCities] = useState<GroupedCities[]>([]);
  const [provinces, setProvinces] = useState<LocationItem[]>([]);
  const [cities, setCities] = useState<LocationItem[]>([]);
  const [init, setInit] = useState<boolean>(true);

  const storeId = formMethods.watch("storeId");

  useEffect(() => {
    LocationApiService.getCitiesGroup().then((groupRes) => {
      setGroupedCities(groupRes.data);
    });
  }, []);

  useEffect(() => {
    setProvinces(
      groupedCities.map((group) => ({
        id: group.province.id,
        name: group.province.name,
      }))
    );
  }, [groupedCities]);

  useEffect(() => {
    if (!init) {
      setCityId(undefined);
    }
    if (provinceId) {
      const cities = groupedCities.find((group) => group.province.id === provinceId)?.cities;
      if (cities) {
        setCities(cities);
      }
    }
  }, [groupedCities, provinceId]);

  useEffect(() => {
    if (!init) {
      setStores([]);
      formMethods.setValue("storeId", "");
    }
    if (cityId) {
      StoreApiService.queryStores({
        sortBy: "name",
        cityId,
      }).then((storesRes) => {
        setStores(storesRes.data.results);
        setFetchingStores(false);
      });
    }
  }, [cityId]);

  // Fetch coupon to edit
  useEffect(() => {
    if (advertisementId) {
      setTitle(advertisementsFormScreenConstants.title.edit());
      AdvertisementsApiService.getAdvertisementDetails(advertisementId)
        .then((adRes) => {
          setAdvertisementToEdit(adRes.data);
        })
        .catch((err) => {
          setNotFound(true);
        });
    } else {
      setTitle(advertisementsFormScreenConstants.title.create);
    }
  }, [advertisementId, setTitle]);

  // Populate form fields with coupon to edit data after data fetched
  useEffect(() => {
    if (provinces.length > 0 && advertisementToEdit) {
      setProvinceId(advertisementToEdit.store.city.provinceId);
      setCityId(advertisementToEdit.store.city.cityId);
      setStores([advertisementToEdit.store]);
      formMethods.setValue("name", advertisementToEdit.name);
      formMethods.setValue(
        "startDate",
        DateTime.fromISO(advertisementToEdit.startDate).toFormat("yyyy-MM-dd")
      );
      formMethods.setValue(
        "endDate",
        DateTime.fromISO(advertisementToEdit.endDate).toFormat("yyyy-MM-dd")
      );
      formMethods.setValue("storeId", advertisementToEdit.store.id);
      setTitle(advertisementsFormScreenConstants.title.edit(advertisementToEdit.name));
    }
  }, [provinces, advertisementToEdit, setProvinceId, setCityId]);

  useEffect(() => {
    if (init) {
      setInit(false);
    }
  }, [storeId]);

  const handleSubmitForm = formMethods.handleSubmit((formData) => {
    if (formData.storeId) {
      let body: AdvertisementsBody = {
        name: formData.name,
        startDate: formData.startDate ? DateTime.fromISO(formData.startDate).toISO() : "",
        endDate: formData.endDate ? DateTime.fromISO(formData.endDate).toISO() : "",
        storeId: formData.storeId,
      };

      const isEdit = !!advertisementId;
      const promise = isEdit
        ? AdvertisementsApiService.updateAdvertisement(advertisementId, body)
        : AdvertisementsApiService.createAdvertisement(body);
      promise
        .then(() => {
          showSnackbar(advertisementsFormScreenConstants.success(isEdit), {
            severity: "success",
          });
          navigate("/publicidades");
        })
        .catch(() => {
          showSnackbar(advertisementsFormScreenConstants.error(isEdit), {
            severity: "error",
          });
        });
    }
  });

  if (notFound) {
    return <NotFoundAnimation />;
  }

  return (
    <Stack>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmitForm}>
          <Grid container mt={3}>
            <Grid item md={6} xs={12}>
              <Grid item xs={12} sm={10} mb={2}>
                <FormSelect
                  value={provinceId}
                  labelId="filter-province"
                  label={advertisementsFormScreenConstants.labels.province}
                  onChange={(e) => setProvinceId(e.target.value as string)}
                  size={"small"}
                >
                  <MenuItem value="">{""}</MenuItem>
                  {provinces.map((province) => (
                    <MenuItem value={province.id}>{province.name}</MenuItem>
                  ))}
                </FormSelect>
              </Grid>
              <Grid item xs={12} sm={10} mb={2}>
                <FormSelect
                  value={cityId}
                  disabled={!provinceId}
                  labelId="filter-city"
                  label={advertisementsFormScreenConstants.labels.city}
                  onChange={(e) => setCityId(e.target.value as string)}
                  size={"small"}
                >
                  <MenuItem value="">{""}</MenuItem>
                  {cities.map((city) => (
                    <MenuItem value={city.id}>{city.name}</MenuItem>
                  ))}
                </FormSelect>
              </Grid>
              <Grid item xs={12} sm={10} mb={2}>
                <ControlledFormSelect
                  name="storeId"
                  disabled={!!advertisementId || !cityId || fetchingStores}
                  control={formMethods.control}
                  rules={{
                    required: advertisementsFormScreenConstants.rules.required,
                  }}
                  label={advertisementsFormScreenConstants.labels.store}
                >
                  {stores.map((store) => (
                    <MenuItem key={"store-" + store.id} value={store.id}>
                      {store.name}
                    </MenuItem>
                  ))}
                </ControlledFormSelect>
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid container justifyContent={"space-between"}>
                <Grid item xs={12} sm={10} mb={2}>
                  <ControlledFormInput
                    name="name"
                    control={formMethods.control}
                    label={advertisementsFormScreenConstants.labels.name}
                    rules={{
                      required: advertisementsFormScreenConstants.rules.required,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={10} mb={2}>
                  <ControlledFormInput
                    type="date"
                    name="startDate"
                    label={advertisementsFormScreenConstants.labels.startDate}
                    control={formMethods.control}
                    rules={{
                      required: advertisementsFormScreenConstants.rules.required,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={10} mb={2}>
                  <ControlledFormInput
                    type="date"
                    name="endDate"
                    label={advertisementsFormScreenConstants.labels.endDate}
                    control={formMethods.control}
                    rules={{
                      required: advertisementsFormScreenConstants.rules.required,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <ButtonsContainer>
            <Button
              text={advertisementsFormScreenConstants.buttons.save(!!advertisementToEdit)}
              variant="contained"
              type={"submit"}
            />
          </ButtonsContainer>
        </form>
      </FormProvider>
    </Stack>
  );
};

export default withLayout(AdvertisementsFormScreen, {
  title: advertisementsFormScreenConstants.title.default,
  card: true,
  navbar: true,
  back: true,
});
