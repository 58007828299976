import { advertisementUtils } from "../../utils/advertisement.utils";

export const getAdvertisementStatus = (advertisement: Advertisement) => {
  let status: {
    color:
      | "error"
      | "success"
      | "info"
      | "default"
      | "primary"
      | "secondary"
      | "warning"
      | undefined;
    label: string;
  };

  if (advertisementUtils.isActive(advertisement)) {
    status = {
      label: "Activo",
      color: "success",
    };
  } else {
    if (advertisementUtils.isExpired(advertisement)) {
      status = {
        label: "Expirado",
        color: "error",
      };
    } else {
      status = {
        label: "Inactivo",
        color: "default",
      };
    }
  }
  return status;
};
