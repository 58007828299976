import { Search } from "@mui/icons-material";
import { InputAdornment, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button, TextField } from "../../common/components/ui";
import { withLayout } from "../../hocs/with-layout";
import advertisementsScreenConstants from "./advertisements-screen.constants";
import AdvertisementsTable from "./components/advertisements-table/advertisements-table.component";
import { AdvertisementsContainer } from "./advertisements-screen.styles";
import { AdvertisementsApiService } from "../../api/advertisements.api.service";

const AdvertisementsScreens = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [advertisements, setAdvertisements] = useState<Advertisement[]>([]);
  const [filteredAdvertisements, setFilteredAdvertisements] = useState<Advertisement[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchAdvertisements = useCallback(() => {
    AdvertisementsApiService.queryAdvertisements().then((advertisementsRes) => {
      setAdvertisements(advertisementsRes.data.results);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    fetchAdvertisements();
  }, [fetchAdvertisements]);

  useEffect(() => {
    if (search) {
      setFilteredAdvertisements(
        advertisements.filter((ad) => ad.store.name.toLowerCase().includes(search.toLowerCase()))
      );
    } else {
      setFilteredAdvertisements(advertisements);
    }
  }, [search, advertisements]);

  return (
    <AdvertisementsContainer>
      <Stack direction={"row"} justifyContent="space-between">
        <TextField
          sx={{ width: 400 }}
          size="small"
          value={search}
          setValue={setSearch}
          placeholder={advertisementsScreenConstants.searchPlaceholder}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />

        <Button
          onClick={() => navigate("/publicidades/crear")}
          text={advertisementsScreenConstants.createAdvertisement}
          variant="contained"
        />
      </Stack>
      <Stack mt={5}>
        <AdvertisementsTable
          fetchAdvertisements={fetchAdvertisements}
          total={advertisements.length}
          advertisements={filteredAdvertisements}
          loading={loading}
        />
      </Stack>
    </AdvertisementsContainer>
  );
};

export default withLayout(AdvertisementsScreens, {
  title: advertisementsScreenConstants.title,
  card: true,
  navbar: true,
});
