import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { StoreApiService, StoreBody } from "../../api/store.api.service";
import useLayout from "../../common/hooks/useLayout";
import { withLayout } from "../../hocs/with-layout";
import { ButtonsContainer } from "./stores-form-screen.styles";
import useStoresForm from "../../common/hooks/useStoresForm";
import { withStoresForm } from "../../hocs/with-stores-form";
import { Button, Stepper } from "../../common/components/ui";
import storesFormScreenConstants, { storesFormSteps } from "./stores-form-screen.constants";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { formDefaultValues, StoreFormInput } from "../../common/context/stores-form.context";
import { UploadApiService } from "../../api/upload.api.service";
import { socialMediaInputToBody } from "./components/social-media-select/social-media-select.constants";
import useSnackbar from "../../common/hooks/useSnackbar";
import NotFoundAnimation from "../not-found/components/not-found-animation.component";
import imageUtils from "../../common/utils/image.utils";

export interface StoresFormStepProps {
  storeToEdit?: Store;
}

interface StoresFormLocationState {
  recommendation?: Recommendation;
}

const StoresFormScreen = () => {
  const { storeId } = useParams();
  const { setTitle } = useLayout();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { state } = useLocation();

  const formMethods = useForm({
    defaultValues: formDefaultValues,
  });

  const provinceId = useWatch({
    control: formMethods.control,
    name: "provinceId",
  });

  const {
    categories,
    cities,
    groupedCities,
    provinces,
    setValuesFromStore,
    setValuesFromRecommendation,
    setCities,
  } = useStoresForm();

  const [activeStep, setActiveStep] = useState<number>(0);
  const [storeToEdit, setStoreToEdit] = useState<Store>();
  const [notFound, setNotFound] = useState<boolean>(false);
  const [recommendation, setRecommendation] = useState<Recommendation>();

  // Fetch store to edit
  useEffect(() => {
    if (storeId) {
      setTitle(storesFormScreenConstants.title.edit());
      StoreApiService.getStoreDetails(storeId)
        .then((storeRes) => {
          setStoreToEdit(storeRes.data);
        })
        .catch((err) => {
          setNotFound(true);
        });
    } else {
      const recommendation = (state as StoresFormLocationState)?.recommendation;
      if (recommendation) {
        setRecommendation(recommendation);
      }
      setTitle(storesFormScreenConstants.title.create);
    }
  }, [storeId]);

  // Populate cities select after province selected
  useEffect(() => {
    formMethods.setValue("cityId", "");
    const cities = groupedCities.find((group) => group.province.id === provinceId)?.cities;
    if (cities) {
      setCities(cities);
    }
  }, [provinceId]);

  // Populate city id field after cities select changed
  useEffect(() => {
    if (storeToEdit || recommendation) {
      const city = cities.find(
        (c) => c.id === storeToEdit?.city.cityId || c.id === recommendation?.city.cityId
      );
      if (city) {
        formMethods.setValue("cityId", city.id);
      }
    }
  }, [cities, storeToEdit, recommendation]);

  // Populate form fields with store to edit data after data fetched
  useEffect(() => {
    if (storeToEdit && categories.length > 0 && provinces.length > 0) {
      setValuesFromStore(storeToEdit, formMethods.setValue);
      setTitle(storesFormScreenConstants.title.edit(storeToEdit.name));
    }
  }, [storeToEdit, categories, provinces]);

  useEffect(() => {
    if (recommendation && categories.length > 0 && provinces.length > 0) {
      setValuesFromRecommendation(recommendation, formMethods.setValue);
    }
  }, [recommendation, categories, provinces]);

  const Step = () => {
    const Component = storesFormSteps[activeStep].component;
    return <Component storeToEdit={storeToEdit} />;
  };

  const onSubmit = formMethods.handleSubmit((formData) => {
    if (activeStep < 3) {
      setActiveStep(activeStep + 1);
    } else {
      handleSubmitForm(formData);
    }
  });

  const handleSubmitForm = async (formData: StoreFormInput) => {
    const { isPartner, ...data } = formData;
    const partialBodyFromData = {
      ...data,
      provinceId: undefined,
    };

    let body: StoreBody = {
      ...partialBodyFromData,
      cover: formData.cover
        ? await UploadApiService.uploadImageV2({
            file: formData.cover,
            fileName: "store-cover." + imageUtils.getFileExtension(formData.cover),
            folder: "/stores",
          })
        : storeToEdit?.cover ?? null,
      logo: formData.logo
        ? await UploadApiService.uploadImageV2({
            file: formData.logo,
            fileName: "store-logo." + imageUtils.getFileExtension(formData.logo),
            folder: "/stores",
          })
        : storeToEdit?.logo ?? null,
      socialMedia: socialMediaInputToBody(formData.socialMedia),
      images: [],
      tier: isPartner ? "partner" : "free",
    };

    const isEdit = !!storeId;
    if (!isEdit && recommendation) {
      body = {
        ...body,
        recommendationId: recommendation.id,
      };
    }
    const promise = isEdit
      ? StoreApiService.updateStore(storeId, body)
      : StoreApiService.createStore(body);
    promise
      .then(() => {
        showSnackbar(storesFormScreenConstants.success(isEdit), {
          severity: "success",
        });
        navigate("/negocios");
      })
      .catch((err) => {
        showSnackbar(storesFormScreenConstants.error(true), {
          severity: "error",
        });
        // setLoading(false);
      });
  };

  if (notFound) {
    return <NotFoundAnimation />;
  }

  return (
    <Stack>
      <Stepper activeStep={activeStep} steps={storesFormSteps} />
      <FormProvider {...formMethods}>
        <form onSubmit={onSubmit}>
          <Step />
          <ButtonsContainer>
            {activeStep > 0 && (
              <Button
                text={storesFormScreenConstants.buttons.goBack}
                variant="contained"
                color="secondary"
                onClick={() => setActiveStep(activeStep - 1)}
              />
            )}
            <Button
              text={
                activeStep === 3
                  ? storesFormScreenConstants.buttons.save(!!storeToEdit)
                  : storesFormScreenConstants.buttons.next
              }
              variant="contained"
              type={"submit"}
            />
          </ButtonsContainer>
        </form>
      </FormProvider>
    </Stack>
  );
};

export default withLayout(withStoresForm(StoresFormScreen), {
  title: storesFormScreenConstants.title.default,
  card: true,
  navbar: true,
  back: true,
});
