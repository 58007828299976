const constants = {
  headers: [
    {
      accessor: "status",
      label: "Estado",
    },
    {
      accessor: "store",
      label: "Negocio",
    },
    {
      accessor: "startDate",
      label: "Desde",
    },
    {
      accessor: "endDate",
      label: "Vencimiento",
    },
    {
      accessor: "name",
      label: "Título",
    },
    {
      accessor: "actions",
      label: "Acciones",
    },
  ],
  editButton: "Editar",
  deleteButton: "Eliminar",
  noAdvertisementsYet: "Aun no hay publicidades",
  noResults: "No hay resultados",
  dialogs: {
    delete: "Seguro que desea eliminar esta publicidad?",
  },
  deletedSuccess: "Publicidad eliminada correctamente",
  deletedError: "Error al eliminar la publicidad",
};

export default constants;
