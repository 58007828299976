import { get, post, put } from "./api.service";

const endpoint = "/stores";

export interface StoreBody {
  // category, city, logo and cover are nullable for ts lint purposes
  // they should NOT be null, API will fail
  name: string;
  description: string;
  categoryId: Nullable<string>;
  cityId: Nullable<string>;
  logo: Nullable<Image>;
  cover: Nullable<Image>;
  features: string[];
  images: string[];
  phoneNumber?: string;
  email?: Nullable<string>;
  webpage?: Nullable<string>;
  socialMedia: SocialMedia[];
  hours?: Nullable<string>;
  address?: Nullable<string>;
  coordinates: Coordinates;
  recommendationId?: string;
  tier?: StoreTier;
}

interface QueryStores {
  name?: string;
  sortBy?: string;
  cityId?: string;
}

const queryStores = (query?: QueryStores) =>
  get<Paginated<SummaryStore>>({
    endpoint,
    auth: true,
    axiosConfig: {
      params: {
        page: 1,
        limit: 9999,
        ...query,
      },
    },
  });

const getStoreDetails = (storeId: string) =>
  get<Store>({
    endpoint: endpoint + "/" + storeId,
    auth: true,
  });

const createStore = (body: StoreBody) =>
  post({
    endpoint,
    auth: true,
    body,
  });

const updateStore = (storeId: string, body: StoreBody) =>
  put({
    endpoint: endpoint + "/" + storeId,
    auth: true,
    body,
  });

export const StoreApiService = {
  queryStores,
  getStoreDetails,
  createStore,
  updateStore,
};
