import { Stack } from "@mui/material";
import { FC, useCallback, useState } from "react";
import { Button, Table } from "../../../../common/components/ui";
import { TableData } from "../../../../common/components/ui/table/table.component";

import { dateUtils } from "../../../../common/utils/date.utils";
import { StoreAvatar } from "./advertisements-table.styles";
import advertisementsTableConstants from "./advertisements-table.constants";
import AdvertisementStatus from "../../../../common/components/advertisement-status/advertisement-status.component";
import ConfirmDialog from "../../../feedbacks/components/confirm-dialog/confirm-dialog.component";
import { AdvertisementsApiService } from "../../../../api/advertisements.api.service";
import useSnackbar from "../../../../common/hooks/useSnackbar";
// import { useNavigate } from "react-router";

interface AdvertisementsTableProps {
  advertisements: Advertisement[];
  loading: boolean;
  total: number;
  fetchAdvertisements: () => void;
}

const AdvertisementsTable: FC<AdvertisementsTableProps> = ({
  advertisements,
  loading,
  total,
  fetchAdvertisements,
}) => {
  const [deleteAdvertisement, setDeleteAdvertisement] = useState<Nullable<string>>(null);
  const { showSnackbar } = useSnackbar();
  // const navigate = useNavigate();

  const getAdvertisementsData = useCallback(() => {
    const data: TableData[] = advertisements.map((ad) => ({
      store: (
        <Stack flexDirection={"row"} alignItems="center">
          {ad.store.logo ? (
            <StoreAvatar src={ad.store.logo.url} />
          ) : (
            <StoreAvatar>{ad.store.name[0]}</StoreAvatar>
          )}
          {ad.store.name}
        </Stack>
      ),
      status: <AdvertisementStatus advertisement={ad} />,
      startDate: dateUtils.formatDate(ad.startDate),
      endDate: dateUtils.formatDate(ad.endDate),
      name: ad.name,
      actions: (
        <Stack>
          {/* <Button
            onClick={() => navigate("/publicidades/editar/" + ad.id)}
            text={advertisementsTableConstants.editButton}
            variant={"text"}
          /> */}
          <Button
            onClick={() => setDeleteAdvertisement(ad.id)}
            text={advertisementsTableConstants.deleteButton}
            variant={"text"}
          />
        </Stack>
      ),
    }));
    return data;
  }, [advertisements, loading, total]);

  const onDeleteAdvertisement = () => {
    if (deleteAdvertisement) {
      AdvertisementsApiService.deleteAdvertisement(deleteAdvertisement)
        .then(() => {
          showSnackbar(advertisementsTableConstants.deletedSuccess, {
            severity: "success",
          });
          fetchAdvertisements();
        })
        .catch(() => {
          showSnackbar(advertisementsTableConstants.deletedError, {
            severity: "error",
          });
        })
        .finally(() => {
          setDeleteAdvertisement(null);
        });
    }
  };

  return (
    <>
      <Table
        loading={loading}
        total={total}
        constants={{
          noDataYet: advertisementsTableConstants.noAdvertisementsYet,
          noResults: advertisementsTableConstants.noResults,
        }}
        headers={advertisementsTableConstants.headers}
        data={getAdvertisementsData()}
        keyName={"advertisements"}
      />
      {deleteAdvertisement && (
        <ConfirmDialog
          text={advertisementsTableConstants.dialogs.delete}
          showModal={!!deleteAdvertisement}
          onClose={() => {
            setDeleteAdvertisement(null);
          }}
          action={onDeleteAdvertisement}
        />
      )}
    </>
  );
};

export default AdvertisementsTable;
