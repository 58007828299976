import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeScreen from "../../screens/home/home-screen.component";
import { AppContainerBox, RoutesContainerBox, ScreenContainer } from "./app-router.styles";
import LoginScreen from "../../screens/login/login-screen.component";
import StoresScreen from "../../screens/stores/stores-screen.component";
import ProtectedRoute from "../protected-route/protected-route.component";
import Drawer from "../drawer/drawer.component";
import useAuth from "../../common/hooks/useAuth";
import { CssBaseline } from "@mui/material";
import StoresFormScreen from "../../screens/stores-form/stores-form-screen.component";
import NotFoundScreen from "../../screens/not-found/not-found-screen.component";
import RecommendationsScreen from "../../screens/recommendations/recommendations-screen.component";
import RecommendationsDetailsScreen from "../../screens/recommendations-details/recommendations-details-screen.component";
import StoreUsersScreen from "../../screens/store-users/store-users-screen.component";
import StoresUsersFormScreen from "../../screens/store-users-form/stores-users-form-screen.component";
import FeedbacksScreen from "../../screens/feedbacks/feedbacks-screen.component";
import CouponsScreen from "../../screens/coupons/coupons-screen.component";
import CouponsDetailsScreen from "../../screens/coupons-details/coupons-details-screen.component";
import CouponsFormScreen from "../../screens/coupons-form/coupons-form-screen.component";
import RewardsScreen from "../../screens/rewards/rewards-screen.component";
import ReviewsScreen from "../../screens/reviews/reviews-screen.component";
import ReviewsDetailsScreen from "../../screens/reviews-details/reviews-details-screen.component";
import NotificationsFormScreen from "../../screens/notifications/notification-form-screen.component";
import AdvertisementsScreen from "../../screens/advertisements/advertisements-screen.component";
import AdvertisementsFormScreen from "../../screens/advertisements-form/advertisements-form-screen.component";

const AppRouter = () => {
  const { user } = useAuth();
  return (
    <AppContainerBox>
      <BrowserRouter>
        <RoutesContainerBox>
          <CssBaseline />
          {user && <Drawer />}
          <ScreenContainer>
            <Routes>
              <Route path="login" element={<LoginScreen />} />
              <Route path="/" element={<ProtectedRoute />}>
                <Route index element={<HomeScreen />} />
                <Route path="negocios">
                  <Route index element={<StoresScreen />} />
                  <Route path="editar/:storeId" element={<StoresFormScreen />} />
                  <Route path="crear" element={<StoresFormScreen />} />
                </Route>
                <Route path="cupones">
                  <Route index element={<CouponsScreen />} />
                  <Route path="crear" element={<CouponsFormScreen />} />
                  <Route path="editar/:couponId" element={<CouponsFormScreen />} />
                  <Route path="ver/:couponId" element={<CouponsDetailsScreen />} />
                </Route>
                <Route path="notificaciones">
                  <Route index element={<NotificationsFormScreen />} />
                </Route>
                <Route path="admin-negocios">
                  <Route index element={<StoreUsersScreen />} />
                  <Route path="editar/:userId" element={<StoresUsersFormScreen />} />
                  <Route path="crear" element={<StoresUsersFormScreen />} />
                </Route>
                <Route path="recomendaciones">
                  <Route index element={<RecommendationsScreen />} />
                  <Route path="ver/:recommendationId" element={<RecommendationsDetailsScreen />} />
                </Route>
                <Route path="sugerencias">
                  <Route index element={<FeedbacksScreen />} />
                </Route>
                <Route path="recompensas">
                  <Route index element={<RewardsScreen />} />
                </Route>
                <Route path="opiniones">
                  <Route index element={<ReviewsScreen />} />
                  <Route path="ver/:reviewId" element={<ReviewsDetailsScreen />} />
                </Route>
                <Route path="publicidades">
                  <Route index element={<AdvertisementsScreen />} />
                  <Route path="crear" element={<AdvertisementsFormScreen />} />
                  <Route path="editar/:advertisementId" element={<AdvertisementsFormScreen />} />
                </Route>
              </Route>
              <Route path="*" element={<NotFoundScreen />} />
            </Routes>
          </ScreenContainer>
        </RoutesContainerBox>
      </BrowserRouter>
    </AppContainerBox>
  );
};

export default AppRouter;
